<template>
  <v-card color="primaryColor">
    <!-- illustration-header -->
    <v-skeleton-loader
      v-if="isFetchingOrganization"
      type="image"></v-skeleton-loader>
    <div
      id="illustration-header"
      v-else>
      <v-img
        v-if="organizationTheme?.profileCoverUrl"
        cover
        height="300"
        :src="organizationTheme?.profileCoverUrl" />
      <v-img
        v-else
        contain
        height="300"
        :src="require('@/assets/profile/header-illustration.png')" />
    </div>

    <!-- profile -->
    <div class="pa-6 profile-details-card white">
      <!-- user-details -->
      <div class="d-flex justify-space-between align-center mb-4">
        <!-- user-avatar -->
        <div class="d-flex justify-between align-center">
          <v-avatar
            class="primaryColor lighten-5 mr-4 primaryColor--text text--darken-3 font-weight-medium avatar text-uppercase"
            size="62">
            {{ userFSDocument?.name?.charAt(0) ?? 'L' }}
          </v-avatar>

          <div class="user-details">
            <!-- name -->
            <div class="user-name font-weight-medium">
              <v-skeleton-loader
                v-if="isLoadingUserDocument"
                type="text"></v-skeleton-loader>
              <span v-else>{{ userFSDocument?.name }}</span>
            </div>
            <!-- organization-name -->
            <v-skeleton-loader
              v-if="isFetchingOrganization"
              type="text"></v-skeleton-loader>
            <div
              v-else
              class="user-organization">
              {{ organizationName }}
            </div>

            <!-- monthly-consumed-time -->
            <v-skeleton-loader
              v-if="isLoadingProfileStats"
              type="text"></v-skeleton-loader>
            <div v-else>
              <span class="font-weight-medium"> {{ monthlyConsumedTimeFormatted }}</span>
              this month
            </div>
          </div>
        </div>
        <!-- settings-button -->
        <div id="settings">
          <v-btn
            @click="navigateToRoute('Settings')"
            icon>
            <v-icon>mdi-cog-outline</v-icon>
          </v-btn>
        </div>
      </div>

      <!-- message-loading -->
      <div v-if="isLoadingStreak">
        <v-skeleton-loader type="article"></v-skeleton-loader>
      </div>

      <!-- message -->
      <v-card
        v-if="!isLoadingStreak && isGreetingVisible"
        class="mb-4 pa-4 elevation-0 message-card"
        color="offWhite">
        <div class="message-card-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="154" height="126" fill="none" viewBox="0 0 154 126" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path stroke="url(#a)" stroke-linecap="round" stroke-linejoin="round" stroke-opacity=".12" stroke-width="13.917" d="M59.146 85.896A17.396 17.396 0 0076.542 68.5c0-9.602-3.48-13.917-6.958-20.875-7.46-14.912-1.559-28.21 13.916-41.75 3.48 17.396 13.917 34.096 27.834 45.23 13.916 11.133 20.875 24.353 20.875 38.27a48.707 48.707 0 01-97.417 0c0-8.023 3.013-15.962 6.958-20.875a17.396 17.396 0 0017.396 17.396v0z"/><defs><linearGradient id="a" x1="34.792" x2="132.209" y1="71.979" y2="71.979" gradientUnits="userSpaceOnUse"><stop stop-color="var(--v-primaryColor-base)"/><stop offset="1" stop-color="var(--v-secondaryColor-base)"/></linearGradient></defs></svg>
        </div>
        <div class="message-header d-flex justify-space-between align-center">
          <div class="message-greetings font-weight-medium primaryColor--text">
            {{ streakMessageHeader }}
          </div>
          <v-btn
            class="px-0 mx-0 text-capitalize"
            color="brandGrey2"
            text
            @click="hideGreeting">
            Hide
          </v-btn>
        </div>

        <div class="message-content">
          {{ streakMessageContent.firstPart }}
          <span class="font-weight-medium">{{ streakOfThisWeek }} day streak</span
          ><span v-if="streakMessageContent.space">&nbsp;</span>{{ streakMessageContent.secondPart }}
        </div>
      </v-card>

      <!-- quick-access -->
      <div class="d-flex justify-space-between align-center my-5">
        <!-- favourites-CTA -->
        <v-btn
          elevation="0"
          @click="navigateToRoute('Favourites')"
          class="quick-access-button primaryColor lighten-3 overflow-hidden py-7 pl-0 rounded-lg"
          :class="{
            'quick-access-light-text': organizationTheme?.primaryColor,
            'quick-access-dark-text': !organizationTheme?.primaryColor,
          }">
          <div class="quick-access-icon pa-4">
            <v-icon>mdi-heart-outline</v-icon>
          </div>
          <div class="quick-access-text">FAVOURITES</div>
        </v-btn>
        <!-- history-CTA -->
        <v-btn
          elevation="0"
          @click="navigateToRoute('History')"
          class="quick-access-button primaryColor lighten-3 overflow-hidden py-7 pl-0 rounded-lg"
          :class="{
            'quick-access-light-text': organizationTheme?.primaryColor,
            'quick-access-dark-text': !organizationTheme?.primaryColor,
          }">
          <div class="quick-access-icon pa-4">
            <v-icon>mdi-update</v-icon>
          </div>
          <div class="quick-access-text">HISTORY</div>
        </v-btn>
      </div>

      <!-- statistics -->
      <v-tabs
        center-active
        v-model="selectedTab"
        fixed-tabs
        class="my-8"
        color="primaryColor">
        <v-tab class="text--black font-weight-medium"> THIS WEEK </v-tab>
        <v-tab class="text--black font-weight-medium"> LAST WEEK </v-tab>

        <v-tabs-items v-model="selectedTab">
          <v-tab-item>
            <WeekStats
              v-if="!isLoadingProfileStats && !isLoadingStreak"
              id="this-week"
              :consumedTimeByDay="consumedTimeThisWeek"
              :streak="streakOfThisWeek"
              :exercicesNumber="totalCoursesThisWeek" />

            <div
              v-else
              class="text-center mt-16">
              <v-progress-circular
                color="primaryColor"
                indeterminate></v-progress-circular>
            </div>
          </v-tab-item>
          <v-tab-item>
            <WeekStats
              v-if="!isLoadingProfileStats && !isLoadingStreak"
              id="last-week"
              :consumedTimeByDay="consumedTimeLastWeek"
              :streak="streakOfLastWeek"
              :exercicesNumber="totalCoursesLastWeek" />

            <div
              v-else
              class="text-center mt-16">
              <v-progress-circular
                color="primaryColor"
                indeterminate></v-progress-circular>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </div>
  </v-card>
</template>

<script>
  import ProfileMixin from '@/views/profile/profile/Profile.mixin.vue';
  export default {
    mixins: [ProfileMixin],
  };
</script>

<style lang="scss" scoped>
  .user-details {
    .user-name {
      color: var(--v-offBlack-base);
    }
  }

  .message-card {
    position: relative;
    border-radius: 16px;
  }

  .message-card-icon {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .message-greetings {
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.16em;
    text-align: left;
  }

  .avatar {
    font-size: 24px;
  }

  .profile-details-card {
    position: absolute;
    top: 20vh;
    border-radius: 40px 40px 0px 0px;
    right: 0;
    left: 0;
  }

  .quick-access-button {
    font-size: 6px;
    width: 49%;
  }

  .quick-access-icon {
    background-color: var(--v-primaryColor-base);
    position: absolute;
    left: 0;
  }

  .quick-access-text {
    font-weight: 600;
    font-size: 12px;
    position: absolute;
    left: 65px;
  }
  .quick-access-light-text {
    color: var(--v-offWhite-base);
  }
  .quick-access-dark-text {
    color: var(--v-offBlack-base);
  }
</style>
